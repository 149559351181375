import React from "react";
import {
  FaExchangeAlt,
  FaBalanceScale,
  FaWallet,
  FaCreditCard,
} from "react-icons/fa";

const services = [
  {
    title: "Swap Tokens",
    description:
      "Trade any combination of ERC-20 tokens permissionless, with ease.",
    icon: <FaExchangeAlt size={30} />,
    action: "Trade Now",
    actionLink: "#",
  },
  {
    title: "Supply Liquidity",
    description:
      "Earn 0.25% fee on trades proportional to your share of the pool.",
    icon: <FaBalanceScale size={30} />,
    action: "LP Now",
    actionLink: "#",
  },
  {
    title: "Perpetual",
    description:
      "Trade decentralised perpetual swaps with up to 100x leverage and zero gas fees.",
    icon: <span className="text-2xl font-bold">100X</span>, // Custom Icon
    action: "Trade Now",
    actionLink: "#",
  },
  {
    title: "Buy w/ Fiat",
    description:
      "The simplest way to get into crypto – buy with Apple Pay, credit card, bank transfer, and more.",
    icon: <FaCreditCard size={30} />,
    action: "Buy Now",
    actionLink: "#",
    highlight: true, // Highlight this card
  },
];

const TradeProvideLiquidity = () => {
  return (
    <div className="bg-[#141827] py-12 px-4 text-white">
      {/* Header Section */}
      <div className="text-center mb-12">
        <h2 className="text-2xl md:text-4xl font-bold text-blue-400">
          Trade & Provide Liquidity
        </h2>
        <p className="text-gray-400 mt-2">
          Swap and LP more than 1,000+ tokens across 80,000+ pairs
        </p>
      </div>

      {/* Service Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 max-w-6xl mx-auto">
        {services.map((service, index) => (
          <div
            key={index}
            className={`flex flex-col hover:shadow-lg hover:shadow-blue-600 justify-between items-start bg-[#1E2132] p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300 ${
              service.highlight ? "border border-blue-900/50 shadow-lg" : ""
            }`}
          >
            <div className="flex items-center justify-center mb-6 text-blue-400">
              {service.icon}
            </div>
            <div>
              <h3 className="text-xl font-bold mb-4">{service.title}</h3>
              <p className="text-gray-400">{service.description}</p>
            </div>
            <a
              href={service.actionLink}
              className="mt-6 text-blue-400 hover:underline flex items-center"
            >
              {service.action} <span className="ml-2">&rarr;</span>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TradeProvideLiquidity;
