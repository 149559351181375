import React from "react";
import logo from "../assets/logo.svg"; // Replace with your logo path
import { FaChevronDown } from "react-icons/fa";

const Navbar = ({ setShowWalletModal }) => {
  return (
    <div className="flex justify-between items-center p-5 border-b border-white/10 bg-[#141827] text-white">
      {/* Left - Logo and Navigation */}
      <div className="flex items-center space-x-8">
        {/* Logo */}
        <img src={logo} alt="Logo" className="w-10 h-10" />

        {/* Menu Items */}
        <div className="md:flex hidden space-x-6 text-sm">
          <span className="hover:text-gray-400 cursor-pointer">Swap</span>
          <span className="flex items-center hover:text-gray-400 cursor-pointer">
            Perps
            <span className="text-xs ml-1 px-1 py-0.5 bg-green-500 text-black rounded-md">
              NEW
            </span>
          </span>
          <span className="hover:text-gray-400 cursor-pointer">Pool</span>
          <div className="flex items-center hover:text-gray-400 cursor-pointer">
            Earn
            <FaChevronDown className="ml-1 text-xs" />
          </div>
          <span className="hover:text-gray-400 cursor-pointer">
            Dragon's Lair
          </span>
          <span className="hover:text-gray-400 cursor-pointer">Bridge</span>
          <span className="hover:text-gray-400 cursor-pointer">Gaming Hub</span>
          <span className="hover:text-gray-400 cursor-pointer">
            Leaderboard
          </span>
        </div>
      </div>

      {/* Right - Network Selector and Button */}
      <div className="flex items-center space-x-4">
        {/* Network Selector */}
        <div className="md:flex hidden items-center px-3 py-1 bg-[#1E2132] text-sm rounded-md cursor-pointer">
          <img
            src="https://cryptologos.cc/logos/polygon-matic-logo.png" // Replace with the Polygon icon path
            alt="Polygon"
            className="w-4 h-4 mr-2"
          />
          Polygon
          <FaChevronDown className="ml-2 text-xs" />
        </div>

        {/* Connect Wallet Button */}
      </div>
    </div>
  );
};

export default Navbar;
