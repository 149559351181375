import React from "react";
import { FaArrowRight } from "react-icons/fa";
import logo from "../assets/logo.svg"; // Replace with your logo path

const Footer = () => {
  return (
    <footer className="bg-[#141827] border-t border-white/10 text-white py-8 px-6">
      {/* Grid Layout for Footer */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-8 max-w-6xl mx-auto">
        {/* Column 1: Products */}
        <div>
          <h3 className="text-lg font-bold mb-4">Products</h3>
          <ul className="space-y-2 text-gray-400">
            <li>Swap</li>
            <li>Perps - zkEVM V1</li>
            <li className="flex items-center">
              Perps - PoS <span className="ml-1 text-orange-500">🔥 New</span>
            </li>
            <li>Pool</li>
            <li>Farm</li>
            <li>Bonds</li>
          </ul>
        </div>

        {/* Column 2: Developers */}
        <div>
          <h3 className="text-lg font-bold mb-4">Developers</h3>
          <ul className="space-y-2 text-gray-400">
            <li>Dragon's Lair</li>
            <li>Gaming Hub</li>
            <li>Leaderboard</li>
            <li>Convert QUICK</li>
            <li>dappOS</li>
            <li>Analytics</li>
          </ul>
        </div>

        {/* Column 3: Governance */}
        <div>
          <h3 className="text-lg font-bold mb-4">Governance</h3>
          <ul className="space-y-2 text-gray-400">
            <li>Proposals</li>
          </ul>
        </div>

        {/* Column 4: About QuickSwap */}
        <div>
          <img src={logo} alt="QuickSwap Logo" className="w-16 mb-4" />
          <p className="text-gray-400 mb-4">
            QuickSwap’s community is building a comprehensive decentralised
            trading platform in the Polygon ecosystem for the future of finance.
            Join the dragon army!
          </p>
          <div>
            <h4 className="text-lg font-bold mb-2">Subscribe to Newsletter</h4>
            <div className="flex items-center bg-[#1E2132] p-2 rounded-lg">
              <input
                type="email"
                placeholder="Enter your email"
                className="flex-1 bg-transparent text-gray-300 placeholder-gray-500 outline-none px-2"
              />
              <button className="text-blue-500">
                <FaArrowRight />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="mt-8 flex flex-col md:flex-row justify-between items-center text-gray-500 text-sm">
        <p>© 2024 QuickSwap.</p>
        <a href="#" className="hover:text-blue-500">
          Terms of Use
        </a>
      </div>
    </footer>
  );
};

export default Footer;
