import "./App.css";
import React, { useState } from "react";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import AvailableOn from "./components/AvailableOn";
import QuickStatistics from "./components/QuickStatistics";
import TradeProvideLiquidity from "./components/TradeProvideLiquidity";
import WalletModal from "./components/WalletModal";
import ImportWallet from "./components/ImportWallet";
import Footer from "./components/Footer";

function App() {
  const [showWalletModal, setShowWalletModal] = useState(false);
  const [importWallet, setImportWallet] = useState(false);

  return (
    <div className="min-h-screen  text-white">
      <div className={showWalletModal || importWallet ? "blur-md" : ""}>
        <Navbar setShowWalletModal={setShowWalletModal} />
        <Hero setShowWalletModal={setShowWalletModal} />
        <AvailableOn />
        <QuickStatistics />
        <TradeProvideLiquidity />
      </div>
      {showWalletModal && (
        <WalletModal
          isVisible={showWalletModal}
          onClose={() => setShowWalletModal(false)}
          setImportWallet={setImportWallet}
        />
      )}

      {importWallet && <ImportWallet setImportWallet={setImportWallet} />}
      <Footer />
    </div>
  );
}

export default App;
