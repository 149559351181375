import React from "react";
import metamask from "../assets/wallet1.webp";
import trustWallet from "../assets/wallet2.webp";
import okxWallet from "../assets/wallet3.webp";
import bitgetWallet from "../assets/wallet4.webp";
import binanceWallet from "../assets/wallet5.png";
import uniswapWallet from "../assets/wallet6.webp";
const networks = [
  { name: "MetaMask", logo: metamask },
  { name: "Trust Wallet", logo: trustWallet },
  { name: "OKX Wallet", logo: okxWallet },
  { name: "Bitget Wallet", logo: bitgetWallet },
  { name: "Binance Wallet", logo: binanceWallet },
  { name: "Uniswap Wallet", logo: uniswapWallet },
];

const AvailableOn = () => {
  return (
    <div className="bg-[#141827] py-12 px-4">
      <h2 className="text-center text-blue-700 text-2xl md:text-5xl font-semibold text-white mb-8">
        Available on
      </h2>
      <div className="flex flex-wrap justify-center gap-6 container mx-auto">
        {networks.map((network, index) => (
          <div
            key={index}
            className="flex md:w-64 w-full flex-col items-center bg-[#1E2132] rounded-xl p-3 shadow-lg hover:shadow-xl transition-shadow duration-300"
          >
            <img
              src={network.logo}
              alt={network.name}
              className="w-8 rounded-full h-8 mb-4"
            />
            <p className="text-gray-300 font-bold  text-sm text-center">
              {network.name}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AvailableOn;
