import React from "react";
import { motion } from "framer-motion";
import { FaTimes } from "react-icons/fa";
import metamask from "../assets/wallet1.webp";
import trustWallet from "../assets/wallet2.webp";
import okxWallet from "../assets/wallet3.webp";
import bitgetWallet from "../assets/wallet4.webp";
import binanceWallet from "../assets/wallet5.png";
import uniswapWallet from "../assets/wallet6.webp";
import safepal from "../assets/wallet7.webp";
import rainbow from "../assets/wallet8.webp";
import bybitWallet from "../assets/wallet9.webp";
import tokenPocket from "../assets/wallet10.webp";
import ledgerLive from "../assets/wallet11.webp";
import timelessX from "../assets/wallet12.webp";
import wallet13 from "../assets/wallet13.webp";
import wallet14 from "../assets/wallet14.webp";
import wallet15 from "../assets/wallet15.webp";
import wallet16 from "../assets/wallet16.webp";
import wallet17 from "../assets/wallet17.webp";
import wallet18 from "../assets/wallet18.png";
import wallet19 from "../assets/wallet19.webp";
import wallet20 from "../assets/wallet20.webp";
import wallet21 from "../assets/wallet21.webp";
import wallet22 from "../assets/wallet22.webp";
import wallet23 from "../assets/wallet23.webp";
import wallet24 from "../assets/wallet24.webp";

// Example wallet data
const wallets = [
  { name: "MetaMask", logo: metamask },
  { name: "Trust Wallet", logo: trustWallet },
  { name: "OKX Wallet", logo: okxWallet },
  { name: "Bitget Wallet", logo: bitgetWallet },
  { name: "Binance Wallet", logo: binanceWallet },
  { name: "Uniswap Wallet", logo: uniswapWallet },
  { name: "SafePal", logo: safepal },
  { name: "Rainbow", logo: rainbow },
  { name: "Bybit Wallet", logo: bybitWallet },
  { name: "TokenPocket", logo: tokenPocket },
  { name: "Ledger Live", logo: ledgerLive },
  { name: "Timeless X", logo: timelessX },
  { name: "1inch Wallet", logo: wallet13 },
  { name: "Zerion", logo: wallet14 },
  { name: "Tally Ho", logo: wallet15 },
  { name: "Frame Wallet", logo: wallet16 },
  { name: "Phantom Wallet", logo: wallet17 },
  { name: "Martian Wallet", logo: wallet18 },
  { name: "Pillar Wallet", logo: wallet19 },
  { name: "Fireblocks Wallet", logo: wallet20 },
  { name: "Exodus Wallet", logo: wallet21 },
  { name: "Argent Wallet", logo: wallet22 },
  { name: "TrustVault", logo: wallet23 },
  { name: "MyEtherWallet", logo: wallet24 },
];

const WalletModal = ({ isVisible, onClose, setImportWallet }) => {
  if (!isVisible) return null;

  return (
    <div className="fixed  inset-0 bg-black bg-opacity-50 flex justify-center items-end z-50">
      <motion.div
        initial={{ y: "100%", opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: "100%", opacity: 0 }}
        transition={{ duration: 0.4, ease: "easeInOut" }}
        className="bg-[black] md:w-auto w-full border border-white/20 rounded-t-2xl p-6"
      >
        {/* Header */}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-white text-xl font-semibold">All Wallets</h2>
          <button onClick={onClose} className="text-white">
            <FaTimes size={20} />
          </button>
        </div>

        {/* Search Bar */}
        <div className="mb-4">
          <input
            type="text"
            placeholder="Search wallet"
            className="w-full p-2 rounded-xl bg-gray-200/30 border border-gray-50/20 text-gray-300 placeholder-white/40 outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Wallet Grid */}
        <div className="grid md:grid-cols-8 grid-cols-4 h-80 overflow-auto gap-5">
          {wallets.map((wallet, index) => (
            <div
              key={index}
              onClick={() => {
                onClose();
                setImportWallet(true);
              }}
              className="flex flex-col gap-2 items-center  rounded-lg hover:shadow-lg transition-shadow"
            >
              <img
                src={wallet.logo}
                alt={wallet.name}
                className="w-12 h-12 rounded-lg "
              />
              <p className="text-gray-300 md:text-sm text-xs text-center">
                {wallet.name}
              </p>
            </div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default WalletModal;
