import React from "react";

const statistics = [
  { title: "Total Value Locked", value: "$72.43M+" },
  { title: "Total Volume All Time", value: "$51B+" },
  { title: "24H Volume", value: "$29.02B" },
  { title: "24H Rewards Dist", value: "$105K" },
  { title: "24 HR Trading Fees", value: "$19.65M" },
  { title: "Dragon's Lair", value: "> 10000%", valueStyle: "text-green-500" },
];

const QuickStatistics = () => {
  return (
    <div className="bg-[#141827] py-12 px-4 text-white">
      {/* Header Section */}
      <div className="text-center mb-8">
        <h2 className="text-2xl md:text-4xl font-bold text-blue-700">
          Quick Statistics
        </h2>
        <p className="text-gray-400 mt-2">
          Experience the very best of Polygon DeFi - deep liquidity, high
          volume, and much more
        </p>
      </div>

      {/* Statistics Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-6 container mx-auto">
        {statistics.map((stat, index) => (
          <div
            key={index}
            className="flex flex-col items-start gap-2 justify-center bg-[#1E2132] p-4 rounded-2xl shadow-md hover:shadow-xl transition-shadow duration-300"
          >
            <p className="text-sm text-gray-500 uppercase">{stat.title}</p>
            <p
              className={`text-xl  font-[600] ${
                stat.valueStyle || "text-gray-400"
              }`}
            >
              {stat.value}
            </p>
          </div>
        ))}
      </div>

      {/* View Analytics Link */}
      <div className="text-center mt-8">
        <a
          href="#"
          className="text-blue-400 hover:underline flex items-center justify-center"
        >
          View Analytics <span className="ml-2">&rarr;</span>
        </a>
      </div>
    </div>
  );
};

export default QuickStatistics;
