import React from "react";
import heroBg from "../assets/bg-primary.webp"; // Replace with the actual image path

const Hero = ({ setShowWalletModal }) => {
  return (
    <div className="relative bg-gray-900 text-white md:py-0 py-10 px-2">
      <div className="grid grid-cols-1 md:grid-cols-3  items-center">
        {/* Left Content */}
        <div className="space-y-6 flex flex-col items-center text-center p-1 md:pl-28">
          <h1 className="text-4xl md:text-5xl leading-10 font-semibold leading-tight">
            Leading DEX on all <br /> Polygon Chains
          </h1>
          <p className="text-gray-300 leading-7 text-md">
            QuickSwap is the home of the DragonFi ecosystem and a leader in
            Polygon CDK adoption. Trade, LP, farm, stake, and more at lightning
            fast speeds and near-zero gas fees.
          </p>
          <button
            onClick={() => {
              setShowWalletModal(true);
            }}
            className="px-4 py-2.5 bg-blue-500 rounded-lg hover:bg-blue-600 text-md font-semibold"
          >
            Connect Wallet
          </button>
        </div>

        {/* Right Image */}
        <div className="relative md:col-span-2">
          <img
            src={heroBg}
            alt="Hero Background"
            className="w-full h-auto object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
